<template>
  <el-container>
    <!-- 左边导航开始 -->
    <el-aside width="200px" style="background-color: rgba(0, 21, 41, 1)">
      <div class="el-title">视频版后台</div>
      <el-menu :default-openeds="[group]" :default-active="item">
        <el-submenu
          v-for="item in list"
          :key="item.group"
          :index="item.group"
          v-if="
            auth_menu_json.some((it) => {
              return it.menu_name == item.name;
            })
          "
        >
          <template slot="title"
            ><i :class="'icon ' + item.icon"></i>{{ item.name }}</template
          >
          <el-menu-item-group>
            <el-menu-item
              v-for="(children, index) in item.child"
              :key="index"
              :index="children['group-item']"
              @click="linkTo(children.url, item.group, children['group-item'])"
              >{{ children.name }}</el-menu-item
            >
          </el-menu-item-group>
        </el-submenu>
      </el-menu>
    </el-aside>
    <!-- 左边导航结束 -->
    <!-- 主要内容开始 -->
    <el-container>
      <el-header>
        <el-dropdown @command="handleCommand">
          <span class="el-dropdown-link">
            {{ userInfo.uname || "--"
            }}<i class="el-icon-arrow-down el-icon--right"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="edit">退出</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </el-header>
      <el-main>
        <keep-alive>
          <router-view v-if="$route.query.keepAlive"></router-view>
        </keep-alive>
        <router-view v-if="!$route.query.keepAlive"></router-view>
      </el-main>
    </el-container>
    <!-- 主要内容结束 -->
  </el-container>
</template>
<script>
import { logout } from "@/api/base";
import Cookies from "js-cookie";
import MENU from "@/util/menu.js";
export default {
  name: "Layout",
  data() {
    return {
      userInfo: JSON.parse(localStorage.getItem("userInfo")) || {},
      auth_menu_json: JSON.parse(localStorage.getItem("auth_menu_json")) || [],
      list: MENU,
    };
  },
  created() {
    console.log(this.auth_menu_json);
  },
  computed: {
    group() {
      // 默认打开左边
      return this.$route.query.group || "1";
    },
    item() {
      // 默认选中索引
      return this.$route.query.item || "1-1";
    },
  },
  methods: {
    // 退出登录
    handleCommand() {
      logout()
        .then(() => {
          // 清空token
          Cookies.remove("token");
          localStorage.removeItem("qn-token");
          this.$router.push("/");
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 跳转页面
    linkTo(link, group, item) {
      this.$router.push({ path: link, query: { group, item } });
    },
  },
};
</script>
<style lang="less">
.el-title {
  padding: 20px 0 10px 0;
  color: #fff;
  font-weight: 600;
  font-size: 24px;
}
.el-main {
  padding: 12px 10px;
  background-color: #e9eef3;
  color: #333;
  text-align: left;
}
.el-container {
  width: 100%;
  height: 100%;
}
.el-header,
.el-footer {
  height: 40px !important;
  color: #333;
  text-align: right;
  line-height: 40px;
}
.el-dropdown-link {
  cursor: pointer;
}

.el-menu {
  border-right: 0;
  background: rgba(0, 0, 0, 0);
}

.el-submenu__title,
.el-submenu__title i,
.el-menu-item {
  color: rgba(255, 255, 255, 0.7);
  font-weight: bold;
}
.el-submenu__title {
  font-size: 15px;
}
.el-menu-item {
  font-weight: 400;
}
.el-submenu__title:hover,
.el-menu-item:hover {
  background-color: #51acf2;
  color: #fff;
}
.el-menu-item.is-active {
  color: #fff;
  background-color: #51acf2;
}
.el-menu-item-group__title {
  padding: 0;
}
.el-cascader-node .el-radio {
  margin: 0 !important;
}
.el-radio {
  margin: 10px 30px 0 0 !important;
}
// 顶部筛选样式
.top-btn {
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
}

.w140 {
  margin-right: 10px;
  width: 140px;
}

.w110 {
  margin-right: 10px;
  width: 110px;
}
</style>
