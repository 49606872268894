const MENU = [
    {
        group: "1",
        icon: "el-icon-s-custom",
        name: "用户管理",
        child: [
            { "group-item": "1-1", url: "/user/userlist", name: "用户列表" },
            { "group-item": "1-2", url: "/user/shareList", name: "分享日志" },
        ],
    },
    {
        group: "2",
        icon: "el-icon-document",
        name: "试听课管理",
        child: [
            {
                "group-item": "2-1",
                url: "/courseOrder/stklist",
                name: "试听课列表",
            },
            {
                "group-item": "2-2",
                url: "/courseOrder/orderList",
                name: "订单列表",
            },
            {
                "group-item": "2-3",
                url: "/courseOrder/groupTracking",
                name: "拼团跟踪",
            },
        ],
    },
    {
        group: "7",
        icon: "el-icon-video-camera-solid",
        name: "视频管理",
        child: [
            {
                "group-item": "7-1",
                url: "/video/stvideolist",
                name: "试听课视频库",
            },
            { "group-item": "7-2", url: "/video/qaManage", name: "问答管理" },
            {
                "group-item": "7-3",
                url: "/video/virtualLike",
                name: "视频添加虚拟点赞",
            },
            { "group-item": "7-4", url: "/video/likeLog", name: "点赞日志" },
            {
                "group-item": "7-5",
                url: "/video/robotLike",
                name: "点赞机器人设置",
            },
        ],
    },
    {
        group: "8",
        icon: "el-icon-s-order",
        name: "优惠券",
        child: [
            { "group-item": "8-1", url: "/coupon/list", name: "优惠券列表" },
            { "group-item": "8-2", url: "/coupon/order", name: "优惠券订单" },
        ],
    },
    {
        group: "3",
        icon: "el-icon-s-tools",
        name: "机构管理",
        child: [
            { "group-item": "3-1", url: "/institutional", name: "机构列表" },
            {
                "group-item": "3-2",
                url: "/institutional/staff",
                name: "员工列表",
            },
            {
                "group-item": "3-3",
                url: "/institutional/invite",
                name: "邀请注册记录",
            },
            {
                "group-item": "3-4",
                url: "/institutional/pointsPrize",
                name: "积分兑换奖品设置",
            },
        ],
    },
    {
        group: "9",
        icon: "el-icon-edit-outline",
        name: "创作学院",
        child: [
            {
                "group-item": "9-1",
                url: "/college/classEdit",
                name: "学院分类",
            },
            {
                "group-item": "9-2",
                url: "/college/articleList",
                name: "文章管理",
            },
            { "group-item": "9-3", url: "/college/homeEdit", name: "首页设置" },
        ],
    },
    {
        group: "4",
        icon: "el-icon-menu",
        name: "分类和标签",
        child: [
            {
                "group-item": "4-1",
                url: "/classTag/classification",
                name: "分类",
            },
            {
                "group-item": "4-2",
                url: "/classTag/tagLibrary",
                name: "标签库",
            },
            {
                "group-item": "4-3",
                url: "/classTag/InterestSettings",
                name: "兴趣设置",
            },
        ],
    },
    {
        group: "5",
        icon: "el-icon-s-data",
        name: "数据分析",
        child: [
            {
                "group-item": "5-5",
                url: "/dataAnalysis/summary",
                name: "数据汇总",
            },
            { "group-item": "5-1", url: "/dataAnalysis/day", name: "日报" },
            { "group-item": "5-2", url: "/dataAnalysis/course", name: "课程" },
            { "group-item": "5-3", url: "/dataAnalysis/video", name: "视频" },
            {
                "group-item": "5-4",
                url: "/dataAnalysis/organization",
                name: "机构",
            },
        ],
    },
    {
        group: "6",
        icon: "el-icon-edit-outline",
        name: "用户营销活动",
        child: [
            {
                "group-item": "6-1",
                url: "/marketing/creditSetting",
                name: "红包抵用金设置",
            },
            {
                "group-item": "6-2",
                url: "/marketing/creditDetail",
                name: "红包抵用金明细",
            },
            {
                "group-item": "6-3",
                url: "/marketing/sendadsSetting",
                name: "机构送广告设置",
            },
            {
                "group-item": "6-4",
                url: "/marketing/sendadsDetail",
                name: "机构送广告明细",
            },
            // {'group-item': '6-5', url: '/marketing/likeActivity', name: '点赞排行活动'}
        ],
    },
    {
        group: "10",
        icon: "el-icon-edit-outline",
        name: "账号设置",
        child: [
            {
                "group-item": "10-1",
                url: "/accountset/manage",
                name: "账号管理",
            },
        ],
    },
    // <!-- 现在最大是10 -->
]

export default MENU